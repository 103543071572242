<template>
  <div class="WorkticketCheckGasRecord has-nav-bar">
    <WorkticketHeader title="气体检测记录"></WorkticketHeader>
    <van-search
      v-if="loadStatus == LOAD_STATUS.SUCCESS"
      v-model="keyword"
      class="search-bar__workticket"
      placeholder="请输入关键词"
    />
    <LoadView v-model="loadStatus" @retry="getList()">
      <van-cell
        v-for="(item, index) in listShow"
        :key="index"
        class="record-item"
        :title="item.location || '--'"
        @click="handleClickItem(index, item.id)"
      >
        <template #title>
          <span class="ellipsis">
            {{ item.location || "--" }}
          </span>
          <p class="date">
            {{ isBy ? item.sampleTime : item.utime }}
          </p>
        </template>
        <template #extra>
          <div style="line-height: initial;">
            <van-icon
              :style="{ color: item.result ? '#8eb433' : '#e97617' }"
              class-prefix="iconfont"
              :name="item.result ? 'hege' : 'buhege'"
              :class="`iconfont-${item.result ? '' : 'bu'}hege`"
              size="42"
            />
          </div>
        </template>
      </van-cell>
    </LoadView>
  </div>
</template>

<script>
import LoadView from "@/components/loadview/LoadView";
import { LOAD_STATUS } from "@/components/loadview/utils";
import WorkticketHeader from "@/components/header/WorkticketHeader";
import { getWorkGasMonitor } from "@/api/workticket";

export default {
  name: "WorkticketCheckGasRecord",
  components: { WorkticketHeader, LoadView },
  data() {
    return {
      specialId: "",
      loadStatus: LOAD_STATUS.LOADING,
      LOAD_STATUS,
      keyword: "",
      list: []
    };
  },
  computed: {
    listShow() {
      if (!this.keyword) {
        return this.list;
      }
      return this.list.filter(
        item => item.location && item.location.indexOf(this.keyword) != -1
      );
    }
  },
  created: function() {},
  mounted: function() {
    this.specialId = this.$route.params.id;
    this.getList();
  },
  methods: {
    getList() {
      getWorkGasMonitor(this.specialId)
        .then(res => {
          if (!res || res.length == 0) {
            this.loadStatus = LOAD_STATUS.EMPTY;
            return;
          }
          this.list = res;
          this.loadStatus = LOAD_STATUS.SUCCESS;
        })
        .catch(error => {
          this.loadStatus = LOAD_STATUS.ERROR;
        });
    },
    handleClickItem(index, id) {
      this.$router.push(`/workticket/checkgas/${id}?simple=1`);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.WorkticketCheckGasRecord {
  .record-item {
    .ellipsis {
      width: 200px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .date {
      font-size: 12px;
      font-weight: 400;
      color: #8c8f97;
      line-height: 17px;
    }
  }
  .LoadView {
    position: initial !important;
  }
}
</style>
